import "../lib/jquery.fullPage";
import gsap from "gsap";
require('jquery-touchswipe');
import Scrollbar from 'smooth-scrollbar';

SE.clsHomepage = (function () {
    //INIT
    function init() {
        // check if is homepage ?
        if ($("#pagehome").length == 0) return;

        // if home0page start to init
        fullpage_init();
        content_init();
        logo_init();
    }

    //FULPAGE
    function fullpage_init() {
        $(document).ready(function () {
            $("#pagehome").fullpage({
                //options here
                css3: false,
                scrollingSpeed : 1000 , 
                autoScrolling: true,
                easing: "swing",
                onLeave: function (origin, destination, direction) {
                    //after leaving section 2
                    let titleValue = '';
                    let elementTitle = $(`.section.section-page[id=section${destination}] .content-desc div[et]`);
                    if (elementTitle.length) {
                        titleValue = elementTitle.attr('page');
                    } else if (destination == 7) {
                        titleValue = 'footer';
                    }
                    if (direction == "down") {
                        var rotate = 90;
                        for (var i = 0; i <= origin - 1; i++) {
                            rotate = rotate - 90;
                        }
                        if (destination - 1 == $(".section").length - 1) {
                            rotate = rotate + 90;
                            gsap.to(".gear", { duration: 0.1, opacity: 0, scale: 0.3, ease: "swing", delay: "-1", });
                            gsap.to(".gear-footer", { duration: 1, y: 25, opacity: 1, scale: 0.3, ease: "swing" });

                            $('#header-js').addClass('hidelogo');
                        }
                        gsap.to([".gear", ".gear-footer"], { duration: 1, rotate: rotate, translate: "0 ,-50%", ease: "swing", });
                        if ($("video").attr("data-keepplaying") == "data-keepplaying") {
                            $("video").attr("data-keepplaying", " ");
                        }
                        dataLayer.push({ 'event': 'Custom - Event', 'CustomEvent - Category': "kharis-landing", 'CustomEvent - Action': "btn-scroll-down", 'CustomEvent - Label': "" });
                    } else if (direction == "up") {
                        var rotate = -450;
                        if (destination - 1 == $(".section").length - 2) {
                            rotate = rotate + 90;
                            var tl1 = gsap.timeline();
                            tl1.to(".gear-footer", { duration: 1, scale: 1, y: 0, opacity: 1, delay: .7})
                            .set(".gear-footer", { opacity: 0 }, '-=.7')
                        }
                        else {
                            for (var i = 6; i >= origin - 1; i--) {
                                rotate = rotate + 90;
                            }
                            var tl = gsap.timeline({onComplete: function() {
                                gsap.set(".gear-footer", { opacity: 0});
                            }});
                            tl.set(".gear-footer", { opacity: 0 })
                            .set(".gear", { opacity: 1, scale: 1 })
                            .to([".gear", ".gear-footer"], { duration: 1, rotate: rotate, translate: "0 ,-50%", ease: "swing", })

                            $("video").attr("data-keepplaying", "data-keepplaying");
                        }
                        // gsap.set(".gear-footer", { opacity: 0, delay: "-1" });

                        $('#header-js').removeClass('hidelogo');
                        dataLayer.push({ 'event': 'Custom - Event', 'CustomEvent - Category': "kharis-landing", 'CustomEvent - Action': "btn-scroll-up", 'CustomEvent - Label': "" });
                    }
                    dataLayer.push({ 'event' :  'Custom - Event',  'CustomEvent - Category' :  "kharis-landing",  'CustomEvent - Action' :  `btn-scroll-to-${titleValue}`,  'CustomEvent - Label' :  ""});

                },
            });

            // methods
            // $.fn.fullpage.setAllowScrolling(false);
            // window.location.pathname
            fullpage_goto()
        });
    }

    function fullpage_goto() {
        // var path = window.location.href;
        // var arr = path.split("#");
        // var page = arr[1];
        var page = localStorage.getItem('page');

        if (page) {
            $.fn.fullpage.moveTo(content_para.arrfunc[page][0]);
            localStorage.removeItem('page');
        }
    }

    // LOGO
    function logo_init() {

        $("#logogrear").swipe({
            //Generic swipe handler for all directions
            swipe: function (event, direction, distance, duration, fingerCount, fingerData) {

                //moveSectionUp();
                //fullpage_api.moveSectionDown();
                if (direction == 'right') {
                    $.fn.fullpage.moveSectionUp(false);

                } else {
                    $.fn.fullpage.moveSectionDown(false);

                }

            }
        });


    }

    // scrollbar
    var scrollbar_para = {
        obj: null,
        turnon: true
    }
    function scrollbar_create() {
        var options = {
            damping: .1,
            alwaysShowTracks: true,
            thumbMinSize: 20,
        }


        if (scrollbar_para.turnon) { // DESKTOP
            $('body').addClass('customscrolling');

            scrollbar_para.obj = Scrollbar.init(document.querySelector('main'), options);

            scrollbar_para.obj.addListener(({ offset }) => {
                var y = offset.y;

                if (y >= 50) {
                    $('#header-js').addClass('fixed_header');
                }
                else {
                    $('#header-js').removeClass('fixed_header');
                }
            })

             // scroll to 100px
             setTimeout(() => {
                scrollbar_para.obj.scrollTo(0, 400, 500);
            }, 1000);
        } else {
            eventscroll_mobile();
            $(window).scroll(function () {
                eventscroll_mobile();
            });
        }

    }

    //scroll-mobile
    function eventscroll_mobile(){
        var y = $(window).scrollTop();
        // Do something
        if (y >= 10) {
            $('#header-js').addClass('fixed_header');
        }
        else {
            $('#header-js').removeClass('fixed_header');
        }
    }

    //check scroll on mobile
    function checkscroll_mobile() {
        if ($('body').hasClass('Mobile') || $('body').hasClass('Tablet')) {
            scrollbar_para.turnon = false;

            setTimeout(() => {
                $('html, body').animate({scrollTop:400},'50');
            }, 1000);
        }
    }

    // AJAX CONTENTS
    var content_para = {
        obj: "#pagehome",
        ajax: "#ajaxcontent",
        arrfunc: {// 'url paramater' of the page : 'classname' of this page
            'about': [1, 'about'],
            'ourapproach': [2, 'clsApproach'],
            'strategicfocus': [3, ''],
            'howweinvest': [4, ''],
            'teamandadvisors': [5, 'clsEcosystem'],
            'brands': [6, 'clsPortfolio']
        },
        page: {
            'about': 'about',
            'ourapproach': 'ourapproach',
            'strategicfocus': 'strategicfocus',
            'howweinvest': 'howweinvest',
            'teamandadvisors': 'teamandadvisors',
            'brands': 'brands'
        }
    };


    function content_init() {
        var loaded = false;

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': jQuery('meta[name="csrf-token"]').attr('content')
            }
        });

        $(".js-cta-homeclick").click(function () {
            if (loaded) return;

            var page = $(this).attr('page');

            content_showdetail(page);
            loaded = true;
            SE.clsGTMTracking.GTM_tracking_pageview(`/${page}`)
        });
    }

    function animate_content() {

        var content_desc_p = $('.section-page.active .inner .content-desc p').text();
        var content_detail_p = $('.section-page.active .inner .content-detail p').text();
        var content_desc = $('.section-page.active .inner .content-desc');
        var content_detail = $('.section-page.active .inner .content-detail');
        var content_cta = $('.section-page.active .inner .content-desc .cta');
        
        var tl = gsap.timeline();
        if (content_desc_p != content_detail_p) {
            
            tl 
                .to([content_desc, content_cta], {duration: .5, opacity: 0})
                .to(content_detail, {duration: .5, opacity: 1, height: 'auto'})
        }
        else {
            tl 
                .to(content_cta, {duration: .5, opacity: 0})
        }
    }

    function content_showdetail(page) {
        
        //animate content
        animate_content();

        // aniamtion gear
        gsap.to('.section-page.active .fp-tableCell', { duration: .5, scale: 1, ease: "none" })
        gsap.to('.gear', { duration: .5, opacity: 0, y: "50%", ease: "none" })

        // process the other blocks
        if (page == 'ourportfolio') {
            $('#section6').addClass('active');
            $(".section-footer.active").remove();
            $(".section-page:not(.active)").remove();
        }

        $(".section-page:not(.active)").remove();
        $(".footer .logo").removeClass('custom-svg');

        // fix current content
        $(".section-page.active").addClass('fixfullpage');

        // call ajax
        $.ajax({
            url: "/getAjaxFullPageContent/" + page,

            success: function (response) {
                $(content_para.obj).append(response);


                // process layout
                $(".footer .logo").removeClass('custom-svg');
                $.fn.fullpage.destroy(); // call to rebuild the fullpage
                $(".section-page.active").removeClass('fixfullpage');

                // fullpage_api.destroy();
                checkscroll_mobile();

                scrollbar_create();

                $('body').addClass('page-ajax');

                // create url history
                history.pushState(null, null, "/" + content_para.page[page]);
                SE.clsMenu.backbutton_set(page);

                // call JS
                var fn_page = SE[content_para.arrfunc[page][1]];

                if (typeof fn_page !== 'undefined') {
                    fn_page.init();
                }


            },
        });
    }



    //history


    //RETURN
    return {
        init: init,
    };
})();

SE.clsHomepage.init();
